import axios from '@/utils/axios';
const baseURL = '/api'
// 绑定房产

// 添加人员时 选择房产
export const myRoomList = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/queryMyOwnerRoom',
    method: 'get',
    params
  });
}; 

// 切换小区
export const changeEstate = (params) => {
  return axios({
    baseURL,
    url: '/blade-auth/wx/switch',
    method: 'get',
    params
  });
}; 

// 我的小区列表
export const myEstateList = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/queryMyVillage',
    method: 'get',
    params
  });
}; 

// 所有小区列表(租户下)
export const getEstateList = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/tenantAllEstate',
    method: 'get',
    params
  });
};

// 我的房产
export const myProperty = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/queryMyRoom',
    method: 'get',
    params
  });
}; 

// 我的房产详情
export const getPropertyDetail = (id) => {
  return axios({
    baseURL,
    url: '/blade-system/village/detail?id=' + id,
    method: 'post',
  });
}; 

// 房屋登记
export const submit = (data) => {
  return axios({
    baseURL,
    url: '/addRoom/record/save',
    method: 'post',
    data
  });
};

// 添加人员
export const addMember = (data) => {
  return axios({
    baseURL,
    url: '/addRoom/record/addPerson',
    method: 'post',
    data
  });
}; 

// // 我的房产
// export const myRoomList = (params) => {
//   return axios({
//     baseURL,
//     url: '/addRoom/record/queryMyOwnerRoom',
//     method: 'get',
//     params
//   });
// }; 

// 我的待审核
export const myVerifyList = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/familyTobeReviewed',
    method: 'get',
    params
  });
}; 

// 我的已审核
export const myVerifyList2 = (params) => {
  return axios({
    baseURL,
    url: '/addRoom/record/familyAlreadyReviewed',
    method: 'get',
    params
  });
}; 

// 提交审核
export const subVerify = (data) => {
  return axios({
    baseURL,
    url: '/addRoom/record/checkResident',
    method: 'post',
    data
  });
}; 

// 我的车辆
export const getMyCar = (data) => {
  return axios({
    baseURL,
    url: '/device/synccar/myCarInfo',
    method: 'post',
    data
  });
}; 

// 车辆详情
export const getCarDetail = (id) => {
  return axios({
    baseURL,
    url: '/device/synccar/detail',
    method: 'get',
    params: id
  });
}; 

// 添加车辆
export const addMyCar = (data) => {
  return axios({
    baseURL,
    url: '/device/synccar/submit',
    method: 'post',
    data
  });
}; 

// 删除车辆
export const removeCar = (params) => {
  return axios({
    baseURL,
    url: '/device/synccar/remove',
    method: 'post',
    params
  });
}; 

