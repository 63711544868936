<template>
  <div class="form-box">
    <van-form
      validate-first
      class="form"
      @submit="appointment"
    >
      <!-- 姓名 -->
      <van-field
        v-model="visitor.realName"
        placeholder="姓名"
        required
        :rules="[{ required: true}]"
      >
        <span slot="label" class="field-label">姓名</span>
      </van-field>

       <!-- 性别  -->
      <van-field
        readonly
        required
        name="datetimePicker"
        :value="visitor.sex"
        placeholder="性别"
        :rules="[{ required: true}]"
        >
        <span slot="label" class="field-label">性别</span>
        <template #input>
          <van-radio-group v-model="visitor.sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        readonly
        clickable
        required
        name="datetimePicker"
        :value="visitor.birthday"
        placeholder="出生日期"
        right-icon="arrow"
        :rules="[{ required: true}]"
        @click="chooseBirthday">
        <span slot="label" class="field-label">出生日期</span>
      </van-field>
      <van-popup
        v-model="showBirthdayPicker"
        position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择时间"
          :columns-order="['year', 'month', 'day']"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirmBirthday"
          @cancel="showBirthdayPicker = false" />
      </van-popup>
      
      <!-- 身份证号 -->
      <van-field
        v-model="visitor.idCard"
        placeholder="身份证号"
        required
        :rules="[{ required: true}]"
      >
        <span slot="label" class="field-label">身份证号</span>
      </van-field>
      <!-- 手机号码 -->
      <van-field
        v-model="visitor.phone"
        placeholder="手机号码"
      >
        <span slot="label" class="field-label">手机号码</span>
      </van-field>
      
      

      <van-field
        v-model="visitor.roomName"
        is-link
        readonly
        required
        placeholder="请选择楼宇、单元、房间"
        :rules="[{ required: true}]"
        @click="showFieldValue = true"
      >
        <span slot="label" class="field-label">所属户室</span>
      </van-field>
      <van-popup v-model="showFieldValue" round position="bottom">
        <!-- <van-cascader
          v-model="cascaderValue"
          title="请选择楼宇、单元、房间"
          :options="options"
          @close="showFieldValue = false"
          @finish="onFieldValue"
        /> -->
        <van-picker
            show-toolbar
            value-key="fullRoomName"
            :columns="roomList"
            @cancel="showFieldValue = false"
            @confirm="onConfirmRoom"
        />
      </van-popup>

      <van-field
        placeholder="请选择身份类型"
        readonly
        required
        :rules="[{ required: true}]"
      >
        <span slot="label" class="field-label">身份类型</span>
        <!-- <div slot="input" class="rowTab">
          <div class="item" :class="{'act': visitor.category == '1'}" @click="toggleAct(1)">家属</div>
          <div class="item" :class="{'act': visitor.category == '2'}" @click="toggleAct(2)">租客</div>
        </div> -->
        <template #input>
          <van-radio-group v-model="visitor.category" direction="horizontal">
              <van-radio :name="item.dictKey" v-for="(item,index) in identityList" :key="index">{{ item.dictValue }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-popup v-model="showIdentity" round position="bottom">
        <van-picker
            show-toolbar
            value-key="dictValue"
            :columns="identityList"
            @cancel="showIdentity = false"
            @confirm="onIdentity"
        />
      </van-popup>
      
      <!-- 时间  -->
      <van-field
        readonly
        clickable
        required
        name="datetimePicker"
        :value="visitor.checkInTime"
        placeholder="入住时间"
        right-icon="arrow"
        :rules="[{ required: true}]"
        @click="chooseCome">
        <span slot="label" class="field-label">入住时间</span>
      </van-field>
      <van-popup
        v-model="showVisitTimePicker"
        position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择时间"
          :columns-order="['year', 'month', 'day']"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirmVisitTime"
          @cancel="showVisitTimePicker = false" />
      </van-popup>

      <!-- 人脸照片 -->
      <!-- <h2 class="category-title-label">人脸图片</h2>
      <van-field>
        <template #input>
          <van-uploader @oversize="oversize" v-model="imgList" :max-size="5 * 1024 * 1024" image-fit="fill" max-count="1" :after-read="uploadPictures" />
        </template>
      </van-field> -->

      <van-field required label="人脸图片">
        <template #input>
          <van-uploader @oversize="oversize" v-model="imgList" :max-size="5 * 1024 * 1024" image-fit="fill" max-count="1" :after-read="uploadPictures" />
        </template>
      </van-field>
      
      <van-button
        block
        type="info" 
        class="submit-btn"
        native-type="submit"
        :loading="subLoading"
      >
        提交
      </van-button>
      
    </van-form>
  </div>
  
</template>

<script>
import { levelList as levelListTree } from '@/api/repairApi'
import { getDictionary } from "@/api/registerApi"

import axios from '@/utils/axios';
import { addMember, myRoomList } from '@/api/houseApi.js'
// import { visitorSave } from '@/api/visitorApi.js'
import { dateFormat  } from "@/utils/date"

export default {
  data() {
    return {
      visitor: {
        category: '',
        faceImg: ''
      },
      showVisitTimePicker: false,
      showBirthdayPicker: false,
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      showFieldValue: false,//小区、楼宇、单元、房间
      fieldValue: '',
      cascaderValue: '',
      options:[],
      imgList: [],
      showIdentity: false,
      showPicker1: false,
      showPicker2: false,
      subLoading: false,
      shequList: [],
      identityList: [],
      xiaoquList: [],
      roomList: [],
    }
  },
  created() {
    // this.getLevelList()
    this.getIdentityList()
    this.getMyRoomList()
  },
  methods: {
    toggleAct(type) {
      this.visitor.category = type
    },
    getMyRoomList() {
      myRoomList().then(res => {
        if(res.code == 200) {
          this.roomList = res.data
        }
      })
    },
    getIdentityList() {
      getDictionary('user_category').then(res => {
        if (res.code === 200) {
          this.identityList = res.data
          this.identityList.map((v, i) => {
            if(v.dictKey == '0') {
              return this.identityList.splice(i, 1)
            }
          })
        }
      })
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    // 提交表单回调
    appointment() {
      if (!this.visitor.category) {
        this.$toast({
            type: 'fail',
            message: '请选择身份类型',
            duration: 1500
        })
        return false
      } else if (!this.visitor.sex) {
        this.$toast({
            type: 'fail',
            message: '请选择性别',
            duration: 1500
        })
        return false
      } else if (!this.visitor.faceImg) {
        this.$toast({
            type: 'fail',
            message: '请上传人脸照片',
            duration: 1500
        })
        return false
      }
      
      this.subLoading = true
      addMember(this.visitor).then(res => {
        this.subLoading = false
        if(res.code == 200) {
          this.$toast({
            type: 'success',
            message: '登记成功',
            duration: 1500,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        } else {
          this.$toast({
            type: 'error',
            message: res.message,
            duration: 1500
          })
        }
      }).catch(() => {
        this.subLoading = false
      })

      // visitorSave(this.visitor).then((res) => {
      //   if(res.code == 200) {
      //     this.$toast({
      //       type: 'success',
      //       message: '预约成功',
      //       duration: 1500,
      //       onClose: () => {
      //         this.$router.go(-1)
      //       }
      //     })
      //   } else {
      //     this.$toast({
      //       type: 'error',
      //       message: res.message,
      //       duration: 1500
      //     })
      //   }
      // })
    },
    // 点击 访问时间
    chooseCome() {
        // this.minDate = new Date()
        this.showVisitTimePicker = true;
    },
    onConfirmRoom(val) {
      this.visitor.streetId = val.streetId
      this.visitor.communityId = val.communityId
      this.visitor.estateId = val.estateId
      this.visitor.buildingId = val.buildingId
      this.visitor.unitId = val.unitId
      this.visitor.roomId = val.roomId
      this.visitor.roomName = val.fullRoomName
      this.showFieldValue = false
    },
    chooseBirthday() {
        // this.minDate = new Date()
        this.showBirthdayPicker = true;
    },
    //  入住时间 选择器
    onConfirmVisitTime(time) {
        this.visitor.checkInTime = dateFormat(time, "YYYY-MM-DD")
        this.showVisitTimePicker = false;
    },
    //  出生日期 选择器
    onConfirmBirthday(time) {
        this.visitor.birthday = dateFormat(time, "YYYY-MM-DD")
        this.showBirthdayPicker = false;
    },
    // 处理到访房间信息
    // onFieldValue({ selectedOptions }) {    
    //   this.showFieldValue = false;
    //   this.fieldValue = selectedOptions.map((option) => option.text).join('');
    //   let fieldValues = selectedOptions.map((option) => option.value);

    //   if(fieldValues.length < 3) {
    //     return this.$toast({
    //         type: 'warning',
    //         message: '楼宇、单元、房间信息不全',
    //         duration: 1000,
    //       })
    //   }
    //   this.visitor.buildingId = selectedOptions[0].id
    //   this.visitor.unitId = selectedOptions[1].id
    //   this.visitor.roomId = selectedOptions[2].id
    //   console.log(selectedOptions);
    // },
    // 通过小区获取楼宇、单元、房间等信息
    getbuild(id){
      levelListTree({communityId:id}).then(res => {
        this.options = res.data[0].children
        // console.log(res);
        this.rewriteKey(this.options)
      })
    },
    // 替换名称
    rewriteKey (val) {
      val.forEach(item => {
        item.text = item.title
        if (item.children) {
          this.rewriteKey(item.children)
        }
      })
    },
    onIdentity(val) { 
      this.visitor.shequId = val.id
      this.visitor.shequName = val.deptName
      this.visitor.tenantId = val.tenantId
      this.showPicker1 = false
      this.fieldValue = ''

    },
    
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    uploadPictures(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      
      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)
      formdata.append('currentUser', 0)

      axios({
        baseURL: '/api',
        url: '/convenient/resident/uploadFace',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          // this.visitor.faceImg = []
          // this.visitor.faceImg.push(res.data.url)
          this.visitor.faceImg = res.data.url
          file.url = res.data.url
          file.status = 'done'
          file.message = ''
        } else {
          this.$toast({
            type: 'warning',
            message: '人脸质量不合格',
            duration: 500,
          })
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.rowTab {
  width: 100%;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  .item {
    font-size: 12px;
    width: 50px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }
  .item.act {
    color: #1989fa;
    border: 1px solid #1989fa;
  }
}
  .my_nav {
    >>> .van-nav-bar__left .van-icon-arrow-left.van-icon {
      font-size: .3rem;
    }
    >>> .van-nav-bar__title {
      font-size: .26rem;
    }
  }
  .main {
    .phone {
      margin-top: 0.075rem;
        >>> .van-field__label {
          width: auto;
        }

    }

    .error-img >>> .van-empty__image {
      width: 1.5rem;
      height: 1.5rem;
    }

    .form {
      margin: 0.15rem 0 .975rem;
      background-color: #fff;
      .category-title-label{
        font-size: 0.37333rem;
        padding: 0.2rem 0;
        margin: 0 0.42667rem;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
        font-weight: 400;
        position: relative;
        &::before {
          content: '*';
          position: absolute;
          left: -8px;
          top: 28%;
          color: #ee0a24;
          font-size: 14px;
        }
      }
      .category-title{
        font-size: 14px;
        padding: 0.2rem 0;
        margin: 0 0.32rem;
        border-bottom: 0.02rem solid #ebedf0;
        &::before{
          content: "";
          display: inline-block;
          width: 3px;
          height: 14px;
          margin-right: 10px;
          vertical-align: middle;
          background: #48a66e;
        }
      }
      .field-left-icon{
          width: 0.375rem;
          font-size: 0.28rem;
      }
      .send {
        color: #1989fa;
      }

      .submit {
        background: #1989fa;;
        color: white;
        border-radius: 5px;
      }
    }

  }
  .center-find-user{
    position: fixed;
    width: 100%;
    top: 50%;
    height: 180px;
    margin-top: -100px;
    text-align: center;
    .icon-find-user-by-tel{
      width: 2rem;
      height: 2rem;
      color: #fff;
      background-color: #48a66e;
      font-size: 1.4rem;
      border-radius: 50%;
      line-height: 2rem;
    }
    .find-input{
      width: 80%;
      margin: 20px auto;
    }

  }
</style>
